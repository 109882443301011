import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["navItem"];

  connect() {
    if (this.isTouchDevice()) {
      this.navItemTarget.addEventListener('touchend', this.toggleDropdown.bind(this));
      document.addEventListener('touchend', this.closeDropdown.bind(this));
    }
  }

  disconnect() {
    if (this.isTouchDevice()) {
      this.navItemTarget.removeEventListener('touchend', this.toggleDropdown.bind(this));
      document.removeEventListener('touchend', this.closeDropdown.bind(this));
    }
  }

  isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
  }

  toggleDropdown(e) {
    // If user touches an element inside the dropdown, return early
    if (e.target.closest('.c-navigation__sub-items')) return;

    // Close other dropdowns
    const allDropdowns = document.querySelectorAll('.touchable');
    allDropdowns.forEach(dropdown => {
      if (dropdown !== this.element) {
        dropdown.classList.remove('touched');
      }
    });

    // Toggle the current dropdown
    this.element.classList.toggle('touched');
  }

  closeDropdown(e) {
    // Close the dropdown if the touch happens outside the current dropdown
    if (!e.target.closest('.touchable')) {
      this.element.classList.remove('touched');
    }
  }
}
